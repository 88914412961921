import { Injectable } from '@angular/core';
declare var freshpaint: any;
@Injectable({
  providedIn: 'root'
})
export class FreshPaintService {

  gclid!: string; 
  constructor() { }

  sendEvent(name: string, body:any){
    if(this.gclid){
      body.$gclid = this.gclid;
      body.$enhanced_conversions_enabled = true;
      body.missing_required_property= "EnableALL"
    }
    freshpaint.track(name, body);
  }
}
